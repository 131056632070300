import { Chapter, Guideline, Section, Subsection } from '@models/Guideline';
import { domStringToBase64 } from '../encode-decode/encode-decode.utils';

export function encodeGuidelinesUpdateRequest(
  guidelines: Guideline
): Guideline {
  return {
    ...guidelines,
    chapters: [...guidelines.chapters.map(encodeGuidelinesChapter)],
    country: {
      ...guidelines.country,
      description: domStringToBase64(guidelines.country.description),
      disclaimer: domStringToBase64(guidelines.country.disclaimer),
    },
  };
}

export function encodeGuidelinesChapter(chapter: Chapter): Chapter {
  return {
    ...chapter,
    sections: [...chapter.sections.map(encodeGuidelinesSection)],
  };
}

export function encodeGuidelinesSection(section: Section): Section {
  return {
    ...section,
    subsections: [...section.subsections.map(encodeGuidelinesSubsection)],
  };
}

export function encodeGuidelinesSubsection(subsection: Subsection): Subsection {
  return {
    ...subsection,
    content: domStringToBase64(subsection.content),
  };
}

export function cleanSubsectionContent(subsection: Subsection): Subsection {
  return {
    ...subsection,
    content: subsection.content.replace('<p>', '').replace('</p>\n', ''),
  };
}
