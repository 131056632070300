import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BusinessUnitCheckboxesComponent } from '@routes/configure/routes/guideline/components/business-unit-checkboxes/business-unit-checkboxes.component';
import { TravelApprovalVariableFieldsComponent } from '@shared/components/travel-approval-variable-fields/travel-approval-variable-fields.component';
import { BusinessLineFilterComponent } from 'app/routes/configure/routes/guideline/components/business-line-filter/business-line-filter.component';
import { TravelApprovalStandardDetailsComponent } from 'app/routes/travel-approval/components/travel-approval-standard-details/travel-approval-standard-details.component';
import { TravelApprovalVariableDetailsComponent } from 'app/routes/travel-approval/components/travel-approval-variable-details/travel-approval-variable-details.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DraggablePanelComponent } from './components/draggable-panel/draggable-panel.component';
import { InputComponent } from './components/input/input.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MobileNonsupportedScreenComponent } from './components/mobile-nonsupported-screen/mobile-nonsupported-screen.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SectionExcerptComponent } from './components/section-excerpt/section-excerpt.component';
import { SortablePanelsComponent } from './components/sortable-panels/sortable-panels.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { TagSelectorComponent } from './components/tag-selector/tag-selector.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TravelApprovalAcknowledgementComponent } from './components/travel-approval-acknowledgement/travel-approval-acknowledgement.component';
import { TravelApprovalActivityTaComponent } from './components/travel-approval-activity-ta/travel-approval-activity-ta.component';
import { TravelApprovalBlotterComponent } from './components/travel-approval-blotter/travel-approval-blotter.component';
import { TravelApprovalDatepickerComponent } from './components/travel-approval-datepicker/travel-approval-datepicker.component';
import { TravelApprovalEmailComponent } from './components/travel-approval-email/travel-approval-email.component';
import { TravelApprovalFileComponent } from './components/travel-approval-file/travel-approval-file.component';
import { TravelApprovalInputComponent } from './components/travel-approval-input/travel-approval-input.component';
import { TravelApprovalMultiSelectComponent } from './components/travel-approval-multi-select/travel-approval-multi-select.component';
import { TravelApprovalPlainTextComponent } from './components/travel-approval-plain-text/travel-approval-plain-text.component';
import { TravelApprovalSingleSelectComponent } from './components/travel-approval-single-select/travel-approval-single-select.component';
import { TravelApprovalStatusBreakdownComponent } from './components/travel-approval-status-breakdown/travel-approval-status-breakdown.component';
import { TravelApprovalTextareaComponent } from './components/travel-approval-textarea/travel-approval-textarea.component';
import { CustomEventManager } from './custom-event-manager';
import { TooltipDirective } from './directives/tooltip.directive';
import { ConfigurationGuidelinesGuard } from './guards/configuration-guidelines/configuration-guidelines.guard';
import { DashboardGuard } from './guards/dashboard/dashboard.guard';
import { DelegationGuard } from './guards/delegation/delegation.guard';
import { ExportGuard } from './guards/export/export.guard';
import { CtaStatusColorPalettePipe } from './pipes/cta-status-color-palette/cta-status-color-palette.pipe';
import { CtaValidationLevelPipe } from './pipes/cta-validation-level/cta-validation-level.pipe';
import { ExtractFieldValidatorPipe } from './pipes/extract-field-validator/extract-field-validator.pipe';
import { FormatApproverCategoryPipe } from './pipes/format-approver-category/format-approver-category.pipe';
import { FormatBooleanPipe } from './pipes/format-boolean/format-boolean.pipe';
import { FromNowPipe } from './pipes/from-now/from-now.pipe';
import { HighlightPipe } from './pipes/highlight/highlight.pipe';
import { JoinStringPipe } from './pipes/join-string/join-string.pipe';
import { Nl2brPipe } from './pipes/nl2br/nl2br.pipe';
import { PluckPipe } from './pipes/pluck/pluck.pipe';
import { ReplaceStringPipe } from './pipes/replace-string/replace-string.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';

export const PIPES = [
  FromNowPipe,
  SafeHtmlPipe,
  Nl2brPipe,
  HighlightPipe,
  CtaStatusColorPalettePipe,
  ReplaceStringPipe,
  FormatApproverCategoryPipe,
  PluckPipe,
  JoinStringPipe,
  ExtractFieldValidatorPipe,
  TruncatePipe,
  CtaValidationLevelPipe,
  FormatBooleanPipe,
];

export const COMPONENTS = [
  BreadcrumbComponent,
  ComboBoxComponent,
  TagInputComponent,
  TagSelectorComponent,
  DatepickerComponent,
  SwitchComponent,
  SectionExcerptComponent,
  LoadingSpinnerComponent,
  SortablePanelsComponent,
  DraggablePanelComponent,
  InputComponent,
  TextareaComponent,
  RadioGroupComponent,
  CheckboxGroupComponent,
  CheckboxComponent,
  LoadingScreenComponent,
  MobileNonsupportedScreenComponent,
  BusinessLineFilterComponent,
  BusinessUnitCheckboxesComponent,
  TravelApprovalActivityTaComponent,
  TravelApprovalVariableFieldsComponent,
  TravelApprovalStandardDetailsComponent,
  TravelApprovalVariableDetailsComponent,
  TravelApprovalBlotterComponent,
  TravelApprovalStatusBreakdownComponent,
  TravelApprovalAcknowledgementComponent,
  TravelApprovalInputComponent,
  TravelApprovalDatepickerComponent,
  TravelApprovalFileComponent,
  TravelApprovalEmailComponent,
  TravelApprovalTextareaComponent,
  TravelApprovalMultiSelectComponent,
  TravelApprovalSingleSelectComponent,
  TravelApprovalPlainTextComponent,
  PaginationComponent,
];

export const DIRECTIVES = [TooltipDirective];

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [...PIPES, ...COMPONENTS, ...DIRECTIVES],
  exports: [...PIPES, ...COMPONENTS, ...DIRECTIVES],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: RootSharedModule,
      providers: [
        CustomEventManager,
        DashboardGuard,
        ExportGuard,
        DelegationGuard,
        ConfigurationGuidelinesGuard,
      ],
    };
  }
}

@NgModule({
  imports: [SharedModule],
})
export class RootSharedModule {}
