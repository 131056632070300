import { Profile } from '@models/Country';
import { Guideline } from '@models/Guideline';
import { GuidelineState } from '@store/guideline/guideline.models';

export const getAcknowledgeOnGoing = (state: GuidelineState): boolean =>
  state.acknowledgeOnGoing;
export const getSubmitQuestionOnGoing = (state: GuidelineState): boolean =>
  state.submitQuestionOnGoing;
export const getMailPopinDisplayed = (state: GuidelineState): boolean =>
  state.mailPopinDisplayed;
export const getGuidelineViewPointOfView = (state: GuidelineState): string =>
  state.guidelineViewPointOfView;
export const getDisplaySubmitPracticalCaseModule = (
  state: GuidelineState
): boolean => state.uiState.displaySubmitPracticalCaseModule;
export const getCountryProfiles = (state: GuidelineState): Profile[] =>
  state.uiState.profiles;
export const getGuidelineForDisplay = (
  state: GuidelineState
): Guideline | undefined => {
  return state?.guidelineForDisplay;
};
