import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ComplianceTravelApproval,
  CtaRequest,
  CtaStats,
} from '@models/ComplianceTravelApproval';
import { PiwikActions, PiwikCategories } from '@models/Piwik';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { convertToDateString } from '@shared/utils/datetime/datetime.utils';
import {
  redirectTo,
  redirectToHome,
  redirectWithQueryParamsTo,
  showDangerNotification,
  showSuccessNotification,
  showWarningNotification,
} from '@store/header/header.actions';
import {
  getCurrentCta,
  getCurrentCtaQuestion,
  getCurrentCtaWithVisibleQuestion,
  getCurrentUser,
  getRouterStateParams,
  getTravelerUser,
} from '@store/root/root.selectors';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { clearQuestions } from '../cta-specific/cta-specific.actions';
import { AppState } from '../root/root.reducer';
import {
  ApproveCta,
  CreateCta,
  DeleteCta,
  DuplicateCta,
  ExportCtaDashboard,
  ExportCtaDashboardSuccess,
  FetchAllCta,
  FetchCountOfWorkingDays,
  FetchCta,
  FetchCtaAlreadyProcessedList,
  FetchCtaForApprovalList,
  FetchCtaForApprovalListSuccess,
  FetchCtaForApprovalListWithFirstCta,
  FetchCtaStats,
  FetchLastCta,
  FetchLateDeclarationWarning,
  FetchLatestCta,
  FetchSpecificCtaForApproval,
  RejectCta,
  SaveCta,
  approveCtaFail,
  approveCtaSuccess,
  clearCtaForDuplication,
  clearSpecificCtaForApproval,
  createCtaFail,
  createCtaSuccess,
  deleteCtaFail,
  deleteCtaSuccess,
  duplicateCtaSuccess,
  exportCtaDashboardFail,
  exportCtaDashboardSuccess,
  fetchAllCtaFail,
  fetchAllCtaSuccess,
  fetchCountOfWorkingDaysFail,
  fetchCountOfWorkingDaysSuccess,
  fetchCtaAlreadyProcessedList,
  fetchCtaAlreadyProcessedListFail,
  fetchCtaAlreadyProcessedListSuccess,
  fetchCtaFail,
  fetchCtaForApprovalListFail,
  fetchCtaForApprovalListSuccess,
  fetchCtaForApprovalListWithFirstCta,
  fetchCtaForApprovalListWithFirstCtaFail,
  fetchCtaForApprovalListWithFirstCtaSuccess,
  fetchCtaForDuplicationSuccess,
  fetchCtaStatsFail,
  fetchCtaStatsSuccess,
  fetchCtaSuccess,
  fetchLastCtaFail,
  fetchLastCtaSuccess,
  fetchLateDeclarationWarningFail,
  fetchLateDeclarationWarningSuccess,
  fetchLatestCtaFail,
  fetchLatestCtaSuccess,
  fetchSpecificCtaForApproval,
  fetchSpecificCtaForApprovalFail,
  fetchSpecificCtaForApprovalSuccess,
  focusCtaForApprovalAtIndex,
  rejectCtaFail,
  rejectCtaSuccess,
  saveCtaFail,
  saveCtaSuccess,
} from './cta.actions';
import {
  encodeRejectCtaRequest,
  getCreateOrUpdateCtaRequest,
} from './cta.effects.utils';
import { RejectCtaRequest } from './cta.models';

@Injectable()
export class CtaEffects {
  public create$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCta>('CREATE_CTA'),
      withLatestFrom(
        this.store$.select(getCurrentCtaWithVisibleQuestion),
        this.store$.select(getCurrentUser),
        this.store$.select(getTravelerUser)
      ),
      switchMap(([action, taData, currentUser, travelerUser]) => {
        const body: CtaRequest = getCreateOrUpdateCtaRequest(
          taData,
          action.onDelegation ? travelerUser : currentUser
        );
        return this.http.post('gateway/travelApprovals', body).pipe(
          mergeMap((res: ComplianceTravelApproval) => {
            return [
              createCtaSuccess(res),
              clearQuestions(),
              showSuccessNotification(
                `You have created a new travel approval request for ${res.destination.name}.`
              ),
              action.onDelegation ? redirectTo('delegation') : redirectToHome(),
            ];
          }),
          catchError((err: HttpErrorResponse) => {
            return [
              createCtaFail(console.error(err)),
              showWarningNotification(err.error.message),
            ];
          })
        );
      })
    )
  );

  public save$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCta>('SAVE_CTA'),
      withLatestFrom(
        this.store$.select(getCurrentCtaQuestion),
        this.store$.select(getCurrentUser),
        this.store$.select(getTravelerUser)
      ),
      switchMap(([action, taData, currentUser, travelerUser]) => {
        const body: CtaRequest = getCreateOrUpdateCtaRequest(
          taData,
          action.onDelegation ? travelerUser : currentUser
        );
        return this.http
          .put(`gateway/travelApprovals/${taData.uuid}`, body)
          .pipe(
            switchMap((res: ComplianceTravelApproval) => {
              return [
                saveCtaSuccess(res),
                clearQuestions(),
                showSuccessNotification(
                  `You have saved your travel approval request for ${res.destination.name}.`
                ),
                action.onDelegation
                  ? redirectTo('delegation')
                  : redirectToHome(),
              ];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                saveCtaFail(console.error(err)),
                showWarningNotification(err.error.message),
              ];
            })
          );
      })
    )
  );

  public delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCta>('DELETE_CTA'),
      withLatestFrom(this.store$.select(getCurrentCta)),
      switchMap(([action, taData]) => {
        return this.http
          .put(`gateway/travelApprovals/${taData.uuid}/cancel`, undefined)
          .pipe(
            mergeMap(() => {
              return [
                deleteCtaSuccess(),
                showSuccessNotification(
                  `You have cancelled the travel approval request for ${taData.destination.name}.`
                ),
                action.onDelegation
                  ? redirectTo('delegation')
                  : redirectTo('travel-approval', 'create'),
              ];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                deleteCtaFail(console.error(err)),
                showWarningNotification(err.error.message),
              ];
            })
          );
      })
    )
  );

  public fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCta>('FETCH_CTA'),
      switchMap(({ ctaUuid, forDuplication }) =>
        this.http.get(`gateway/travelApprovals/${ctaUuid}`).pipe(
          mergeMap((res: ComplianceTravelApproval) => {
            if (forDuplication) {
              return [
                fetchCtaForDuplicationSuccess(res),
                clearCtaForDuplication(),
              ];
            }
            return [fetchCtaSuccess(res)];
          }),
          catchError((err: HttpErrorResponse) => {
            return [
              fetchCtaFail(console.error(err)),
              showDangerNotification(err.error.message),
            ];
          })
        )
      )
    )
  );

  public fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAllCta>('FETCH_ALL_CTA'),
      switchMap(() =>
        this.http.get('gateway/travelApprovals/myself/latest').pipe(
          map((res: ComplianceTravelApproval[]) => fetchAllCtaSuccess(res)),
          catchError((err: HttpErrorResponse) =>
            of(fetchAllCtaFail(console.error(err)))
          )
        )
      )
    )
  );

  public fetchLast$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchLastCta>('FETCH_LAST_CTA'),
      switchMap(({ travelerEmail }) => {
        return this.http
          .get(
            'gateway/travelApprovals/last',
            travelerEmail
              ? {
                  params: {
                    traveler: travelerEmail,
                  },
                }
              : undefined
          )
          .pipe(
            map((res: ComplianceTravelApproval) => fetchLastCtaSuccess(res)),
            catchError((err: HttpErrorResponse) => {
              return [fetchLastCtaFail(console.error(err))];
            })
          );
      })
    )
  );

  public fetchLatest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchLatestCta>('FETCH_LATEST_CTA'),
      switchMap(({ payload: payload }) =>
        this.http
          .get('gateway/travelApprovals/myself/latest', {
            params: {
              limit: String(payload),
            },
          })
          .pipe(
            map((res: ComplianceTravelApproval[]) =>
              fetchLatestCtaSuccess(res)
            ),
            catchError((err: HttpErrorResponse) => {
              return [fetchLatestCtaFail(console.error(err))];
            })
          )
      )
    )
  );

  public fetchStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCtaStats>('FETCH_CTA_STATS'),
      switchMap(({ payload: payload }) => {
        return this.http
          .get('gateway/travelApprovals/stats', {
            params: {
              countryCode: payload.countryCode,
              startDate: convertToDateString(payload.startDate),
              endDate: convertToDateString(payload.endDate),
            },
          })
          .pipe(
            map((res: CtaStats) => fetchCtaStatsSuccess(res)),
            catchError((err: HttpErrorResponse) =>
              of(fetchCtaStatsFail(console.error(err)))
            )
          );
      })
    )
  );

  public fetchSpecificCtaForApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSpecificCtaForApproval>('FETCH_SPECIFIC_CTA_FOR_APPROVAL'),
      mergeMap(({ payload }) => {
        return this.http.get(`gateway/travelApprovals/${payload}`).pipe(
          map((res: ComplianceTravelApproval) => {
            return fetchSpecificCtaForApprovalSuccess(res);
          }),
          catchError((err: HttpErrorResponse) => {
            return [
              fetchSpecificCtaForApprovalFail(console.error(err)),
              showDangerNotification(err.error.message),
            ];
          })
        );
      })
    )
  );

  public fetchCtaForApprovalList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCtaForApprovalList>('FETCH_CTA_FOR_APPROVAL_LIST'),
      switchMap(() =>
        this.http.get('gateway/travelApprovals/myself/forApproval').pipe(
          map((res: ComplianceTravelApproval[]) =>
            fetchCtaForApprovalListSuccess(res)
          ),
          catchError((err: HttpErrorResponse) =>
            of(fetchCtaForApprovalListFail(console.error(err)))
          )
        )
      )
    )
  );

  public fetchCtaForApprovalListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCtaForApprovalListSuccess>(
        'FETCH_CTA_FOR_APPROVAL_LIST_SUCCESS'
      ),
      withLatestFrom(this.store$.select(getRouterStateParams)),
      switchMap(([action, params]) => {
        const { payload: ctasForApproval } = action;
        if (ctasForApproval && ctasForApproval.length) {
          if (
            params.uuid &&
            ctasForApproval.findIndex((cta) => cta.uuid === params.uuid) > 0
          ) {
            return [fetchSpecificCtaForApproval(params.uuid)];
          } else {
            return [fetchSpecificCtaForApproval(ctasForApproval[0].uuid)];
          }
        } else {
          return of();
        }
      })
    )
  );

  public fetchCtaForApprovalListWithFirstCta$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCtaForApprovalListWithFirstCta>(
        'FETCH_CTA_FOR_APPROVAL_LIST_WITH_FIRST_CTA'
      ),
      switchMap(() =>
        this.http.get('gateway/travelApprovals/myself/forApproval').pipe(
          mergeMap((res: ComplianceTravelApproval[]) => {
            if (res.length) {
              return [
                fetchCtaForApprovalListWithFirstCtaSuccess(res),
                fetchSpecificCtaForApproval(res[0].uuid),
                focusCtaForApprovalAtIndex(0),
              ];
            } else {
              return [
                fetchCtaForApprovalListWithFirstCtaSuccess(res),
                clearSpecificCtaForApproval(),
              ];
            }
          }),
          catchError((err: HttpErrorResponse) =>
            of(fetchCtaForApprovalListWithFirstCtaFail(console.error(err)))
          )
        )
      )
    )
  );

  public fetchCtaAlreadyProcessedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCtaAlreadyProcessedList>('FETCH_CTA_ALREADY_PROCESSED_LIST'),
      switchMap(() =>
        this.http.get('gateway/travelApprovals/myself/alreadyProcessed').pipe(
          map((res: ComplianceTravelApproval[]) =>
            fetchCtaAlreadyProcessedListSuccess(res)
          ),
          catchError((err: HttpErrorResponse) =>
            of(fetchCtaAlreadyProcessedListFail(console.error(err)))
          )
        )
      )
    )
  );

  public reject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RejectCta>('REJECT_CTA'),
      switchMap(({ payload }) => {
        const body: RejectCtaRequest = {
          rejectionComment: payload.rejectionComment,
        };

        return this.http
          .post(
            `gateway/travelApprovals/${payload.ctaId}/reject`,
            encodeRejectCtaRequest(body)
          )
          .pipe(
            mergeMap((res: any) => {
              this.matomoTracker.trackEvent(
                PiwikCategories.ApprovalPage,
                PiwikActions.RejectCta
              );
              return [
                rejectCtaSuccess(),
                fetchCtaForApprovalListWithFirstCta(),
                fetchCtaAlreadyProcessedList(),
                showSuccessNotification(`${res.message}`, true),
              ];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                rejectCtaFail(console.error(err)),
                showDangerNotification(err.error.message, true),
              ];
            })
          );
      })
    )
  );

  public approve$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveCta>('APPROVE_CTA'),
      switchMap(({ ctaId: ctaId }) => {
        return this.http
          .post(`gateway/travelApprovals/${ctaId}/approve`, undefined)
          .pipe(
            mergeMap((res: any) => {
              this.matomoTracker.trackEvent(
                PiwikCategories.ApprovalPage,
                PiwikActions.ApproveCta
              );
              return [
                approveCtaSuccess(),
                fetchCtaForApprovalListWithFirstCta(),
                fetchCtaAlreadyProcessedList(),
                showSuccessNotification(`${res.message}`, true),
              ];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                approveCtaFail(console.error(err)),
                showDangerNotification(err.error.message, true),
              ];
            })
          );
      })
    )
  );

  public fetchCountOfWorkingDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCountOfWorkingDays>('FETCH_COUNT_OF_WORKING_DAYS'),
      switchMap(({ country, startDate, endDate, editedCtaUuid, traveler }) => {
        const params = setParamsFetchWarningMessage(
          startDate,
          endDate,
          editedCtaUuid,
          traveler
        );

        return this.http
          .get(`gateway/travelApprovals/${country.alpha2}/workingDays`, {
            params,
          })
          .pipe(
            map((res: any) => fetchCountOfWorkingDaysSuccess(res)),
            catchError((err: HttpErrorResponse) =>
              of(fetchCountOfWorkingDaysFail(console.error(err)))
            )
          );
      })
    )
  );

  public fetchLateDeclarationWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchLateDeclarationWarning>('FETCH_LATE_DECLARATION_WARNING'),
      switchMap(({ startDate }) => {
        return this.http
          .get('gateway/travelApprovals/lateDeclaration', {
            params: {
              startDate,
            },
          })
          .pipe(
            map((res: any) => fetchLateDeclarationWarningSuccess(res)),
            catchError((err: HttpErrorResponse) =>
              of(fetchLateDeclarationWarningFail(console.error(err)))
            )
          );
      })
    )
  );

  public duplicateCta$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateCta>('DUPLICATE_CTA'),
      switchMap(({ traveler }) => {
        return [
          duplicateCtaSuccess(),
          redirectWithQueryParamsTo('travel-approval/create', {
            traveler,
          }),
        ];
      })
    )
  );

  public exportCtaDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExportCtaDashboard>('EXPORT_CTA_DASHBOARD'),
      switchMap(({ direction, country, businessUnit, startDate, endDate }) =>
        this.http
          .get('v2/export/travelApprovals', {
            params: {
              direction: direction.toLowerCase(),
              ...(country && { country }),
              ...(businessUnit && { businessUnit }),
              // country,
              // businessUnit,
              fromDate: startDate,
              toDate: endDate,
            },
            responseType: 'blob',
          })
          .pipe(
            map((res: Blob) => {
              this.matomoTracker.trackEvent(
                PiwikCategories.ExportPage,
                PiwikActions.ExportCtaDashboard,
                country
              );
              return exportCtaDashboardSuccess(res);
            }),
            catchError((err) => {
              return [
                exportCtaDashboardFail(console.error(err)),
                showDangerNotification(`Couldn't export the TAR dashboard.`),
              ];
            })
          )
      )
    )
  );

  public exportCtaDashboardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExportCtaDashboardSuccess>('EXPORT_CTA_DASHBOARD_SUCCESS'),
        tap(({ blob: data }) => {
          const blob = new Blob([data], { type: data.type });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = 'cta_dashboard.xlsx';
          a.target = '_blank';
          a.click();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private http: HttpClient,
    private matomoTracker: MatomoTracker
  ) {}
}

function setParamsFetchWarningMessage(
  startDate: string,
  endDate: string,
  editedCtaUuid: string,
  traveler: string
) {
  let params = new HttpParams();
  if (editedCtaUuid) {
    params = params.set('uuid', editedCtaUuid);
  }
  if (traveler) {
    params = params.set('traveler', traveler);
  }
  params = params.set('startDate', startDate);
  params = params.set('endDate', endDate);

  return params;
}
