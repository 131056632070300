import { Access } from '@models/Access';

export enum SGIAMPermissions {
  EXPORT_CTA = 'EXPORT_CTA',
  EXPORT_ACK = 'EXPORT_ACK',
  EXPORT_GUIDELINES = 'EXPORT_GUIDELINES',
  READ_WRITE_GUIDELINES = 'READ_WRITE_GUIDELINES',
  READONLY_GUIDELINES = 'READONLY_GUIDELINES',
  VIEW_CTA_PERIMETER = 'VIEW_CTA_PERIMETER',
  VIEW_CTA_ALL = 'VIEW_CTA_ALL',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  MANAGE_COMPLIANCE_OFFICERS = 'MANAGE_COMPLIANCE_OFFICERS',
  MANAGE_OTHER_APPROVERS = 'MANAGE_OTHER_APPROVERS',
  MANAGE_SUPERVISORS = 'MANAGE_SUPERVISORS',
}

export enum AccessiblePages {
  HOME = 'Home',
  APPROVALS = 'Approvals',
  EXPORT = 'Export',
  DASHBOARD = 'Dashboard',
  CONTACTS_DETAILS = 'ContactsDetails',
  DELEGATION = 'Delegation',
}

export const MOCK_ADMINISTRATOR_PERMISSIONS: string[] = [
  SGIAMPermissions.EXPORT_ACK,
  SGIAMPermissions.EXPORT_CTA,
  SGIAMPermissions.EXPORT_GUIDELINES,
  SGIAMPermissions.VIEW_DASHBOARD,
  SGIAMPermissions.VIEW_CTA_ALL,
  SGIAMPermissions.READ_WRITE_GUIDELINES,
];

export const MOCK_SSC_PERMISSIONS: string[] = [
  SGIAMPermissions.READ_WRITE_GUIDELINES,
];

export const MOCK_SUPERVISION_PERMISSIONS: string[] = [
  SGIAMPermissions.EXPORT_CTA,
  SGIAMPermissions.EXPORT_ACK,
  SGIAMPermissions.VIEW_DASHBOARD,
  SGIAMPermissions.VIEW_CTA_PERIMETER,
  SGIAMPermissions.READONLY_GUIDELINES,
];

export const MOCK_API_ACCESS: Access = {
  pages: [
    AccessiblePages.HOME,
    AccessiblePages.APPROVALS,
    AccessiblePages.EXPORT,
    AccessiblePages.DASHBOARD,
    AccessiblePages.DELEGATION,
  ],
  permissions: MOCK_ADMINISTRATOR_PERMISSIONS,
};
