import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  GuidelineAction,
  SourceVersionOwner,
} from '@models/SourceVersionOwner';
import { ValidatorRule } from '@models/Validator';
import { updateItemByIndexInObjectArray } from '@shared/utils/helper/helper.utils';
import { BusinessUnitCheckboxState } from '@store/business-unit/business-unit.models';

@Component({
  selector: 'sgxb-business-unit-checkboxes',
  templateUrl: './business-unit-checkboxes.component.html',
  styleUrls: ['./business-unit-checkboxes.component.scss'],
})
export class BusinessUnitCheckboxesComponent {
  @Input() public sourceOwners: SourceVersionOwner[];
  @Input() public selectedBusinessUnits: string[];
  @Output() public update = new EventEmitter<BusinessUnitCheckboxState[]>();
  @Output() public validate = new EventEmitter<boolean>();

  public validators = [
    {
      rule: ValidatorRule.REQUIRED,
      message: 'Please select at least 1 Business Unit.',
      constraint: null,
    },
  ];
  public warnings: string[] = [];

  public get formValidity() {
    this.validate.emit(this.validity);
    return this.validity;
  }

  public get validity(): boolean {
    this.warnings = [];
    const businessUnitsChecked = this.checkboxBusinessUnits.filter(
      (checkboxBusinessUnit) => checkboxBusinessUnit.checked
    );

    this.warnings = this.validators.reduce<string[]>(
      (accumulator, validator) => {
        const { rule, message } = validator;
        if (
          rule === ValidatorRule.REQUIRED &&
          (!businessUnitsChecked || businessUnitsChecked.length === 0)
        ) {
          accumulator.push(message);
        }
        return accumulator;
      },
      this.warnings
    );

    // Valid if no warnings to be raised
    return this.warnings.length === 0;
  }

  public get checkboxBusinessUnits(): BusinessUnitCheckboxState[] {
    return (
      this.sourceOwners?.map((sourceOwner) => {
        return {
          value: sourceOwner.businessUnit,
          checked: this.selectedBusinessUnits.includes(
            sourceOwner.businessUnit
          ),
          readonly: sourceOwner.action === GuidelineAction.DELETE,
        };
      }) ?? []
    );
  }

  constructor() {}

  public onClick(_event: MouseEvent, index: number) {
    this.toggleSelectBusinessUnit(index);
  }

  private toggleSelectBusinessUnit(index: number): void {
    console.log(index, this.checkboxBusinessUnits);
    if (
      index === null ||
      index < 0 ||
      this.checkboxBusinessUnits[index] === undefined ||
      this.checkboxBusinessUnits[index].readonly
    ) {
      return;
    }
    const wasChecked =
      this.checkboxBusinessUnits[index] &&
      this.checkboxBusinessUnits[index].checked;
    const newSelectedBusinessUnits = updateItemByIndexInObjectArray(
      this.checkboxBusinessUnits,
      index,
      {
        checked: !wasChecked,
      }
    );
    this.update.emit(newSelectedBusinessUnits);
  }
}
