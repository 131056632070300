import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';
import { Action } from '@store/action';
import {
  FetchPreviewFromQuestionsSuccess,
  FetchQuestionsForDuplicationSuccess,
  FetchQuestionsSuccess,
} from '@store/cta-specific/cta-specific.actions';
import {
  UpdateCtaAnswer,
  UpdateCtaAnswerOnCcCheckbox,
  UpdateCtaAnswerOnMultiSelectAdd,
  UpdateCtaAnswerOnMultiSelectRemove,
  UpdateCtaAnswerValidity,
} from './cta.actions';
import { CtaState, initialCtaState } from './cta.models';
import {
  getAnswerForDuplicationSuccess,
  patchUiStateCurrentCtaAnswer,
  patchUiStateCurrentCtaAnswers,
} from './cta.reducer.helper';

export function ctaReducer(
  state: CtaState = initialCtaState,
  action: Action
): CtaState {
  switch (action.type) {
    case 'NEW_CTA': {
      return {
        ...state,
        current: initialCtaState.current,
        uiState: {
          ...state.uiState,
          current: initialCtaState.current,
        },
      };
    }
    case 'UPDATE_CTA': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          current: {
            ...state.uiState.current,
            ...action.payload,
          },
        },
      };
    }
    case 'UPDATE_CTA_ANSWER':
      return onUpdateCtaAnswer(state, action);
    case 'UPDATE_CTA_ANSWER_ON_CC_CHECKBOX':
      return onUpdateCtaAnswerOnCcCheckbox(state, action);
    case 'UPDATE_CTA_ANSWER_ON_MULTI_SELECT_ADD':
      return onUpdateCtaAnswerOnMultiSelectAdd(state, action);
    case 'UPDATE_CTA_ANSWER_ON_MULTI_SELECT_REMOVE':
      return onUpdateCtaAnswerOnMultiSelectRemove(state, action);
    case 'UPDATE_CTA_ANSWER_VALIDITY':
      return onUpdateCtaAnswerValidity(state, action);
    case 'RESET_CTA':
      return onResetCta(state);
    case 'CREATE_CTA':
    case 'DELETE_CTA':
    case 'SAVE_CTA': {
      return { ...state, submitOnGoing: true };
    }
    case 'CREATE_CTA_FAIL':
    case 'DELETE_CTA_FAIL':
    case 'SAVE_CTA_FAIL':
    case 'DELETE_CTA_SUCCESS': {
      return { ...state, submitOnGoing: false };
    }
    case 'CREATE_CTA_SUCCESS':
    case 'SAVE_CTA_SUCCESS': {
      return {
        ...state,
        current: action.payload,
        submitOnGoing: false,
        uiState: {
          ...state.uiState,
          current: action.payload,
        },
      };
    }

    case 'FETCH_CTA': {
      const currentState = action.forDuplication
        ? state.uiState.current
        : initialCtaState.current;
      return {
        ...state,
        fetchOnGoing: true,
        current: currentState,
        uiState: {
          ...state.uiState,
          current: currentState,
          workingDaysMessage: null,
        },
      };
    }
    case 'FETCH_CTA_FAIL': {
      return {
        ...state,
        fetchOnGoing: false,
        current: initialCtaState.current,
        uiState: {
          ...state.uiState,
          current: initialCtaState.current,
        },
      };
    }
    case 'FETCH_CTA_SUCCESS': {
      return {
        ...state,
        fetchOnGoing: false,
        current: action.payload,
        uiState: {
          ...state.uiState,
          current: action.payload,
        },
      };
    }
    case 'FETCH_CTA_FOR_DUPLICATION_SUCCESS': {
      return onFetchCtaForDuplicationSuccess(state, action.payload);
    }
    case 'FETCH_ALL_CTA': {
      return { ...state, fetchOnGoing: true, all: initialCtaState.all };
    }
    case 'FETCH_ALL_CTA_FAIL': {
      return { ...state, fetchOnGoing: false, all: initialCtaState.all };
    }
    case 'FETCH_ALL_CTA_SUCCESS': {
      return { ...state, fetchOnGoing: false, all: action.payload };
    }

    case 'FETCH_LAST_CTA': {
      return {
        ...state,
        fetchOnGoing: true,
        current: initialCtaState.current,
        uiState: {
          ...state.uiState,
          current: initialCtaState.current,
          workingDaysMessage: null,
        },
      };
    }
    case 'FETCH_LAST_CTA_FAIL': {
      return {
        ...state,
        fetchOnGoing: false,
        current: initialCtaState.current,
        uiState: {
          ...state.uiState,
          current: initialCtaState.current,
          rejectModeEnabled: false,
        },
      };
    }
    case 'FETCH_LAST_CTA_SUCCESS': {
      return {
        ...state,
        fetchOnGoing: false,
        current: {
          ...initialCtaState.current,
          origin:
            action.payload && action.payload.origin
              ? action.payload.origin
              : null,
          manager:
            action.payload && action.payload.manager
              ? action.payload.manager
              : '',
          ccs: action.payload && action.payload.ccs ? action.payload.ccs : [],
          answers: state.current.answers,
          user: action.payload ? action.payload.user : null,
        },
        uiState: {
          ...state.uiState,
          current: {
            ...initialCtaState.current,
            origin:
              action.payload && action.payload.origin
                ? action.payload.origin
                : null,
            manager:
              action.payload && action.payload.manager
                ? action.payload.manager
                : '',
            ccs: action.payload && action.payload.ccs ? action.payload.ccs : [],
            answers: state.uiState.current.answers,
            user: action.payload ? action.payload.user : null,
          },
        },
      };
    }

    case 'FETCH_LATEST_CTA': {
      return { ...state, fetchOnGoing: true, latest: initialCtaState.latest };
    }
    case 'FETCH_LATEST_CTA_FAIL': {
      return { ...state, fetchOnGoing: false, latest: initialCtaState.latest };
    }
    case 'FETCH_LATEST_CTA_SUCCESS': {
      return { ...state, fetchOnGoing: false, latest: action.payload };
    }

    case 'FETCH_CTA_STATS': {
      return {
        ...state,
        fetchStatsOnGoing: true,
        stats: initialCtaState.stats,
      };
    }
    case 'FETCH_CTA_STATS_FAIL': {
      return {
        ...state,
        fetchStatsOnGoing: false,
        stats: initialCtaState.stats,
      };
    }
    case 'FETCH_CTA_STATS_SUCCESS': {
      return { ...state, fetchStatsOnGoing: false, stats: action.payload };
    }

    case 'FETCH_SPECIFIC_CTA_FOR_APPROVAL': {
      return {
        ...state,
        fetchOnGoing: true,
        specificCtaForApproval: initialCtaState.specificCtaForApproval,
        uiState: {
          ...state.uiState,
          specificCtaForApproval: initialCtaState.specificCtaForApproval,
        },
      };
    }
    case 'FETCH_SPECIFIC_CTA_FOR_APPROVAL_FAIL': {
      return {
        ...state,
        fetchOnGoing: false,
        specificCtaForApproval: initialCtaState.specificCtaForApproval,
        uiState: {
          ...state.uiState,
          specificCtaForApproval: initialCtaState.specificCtaForApproval,
        },
      };
    }
    case 'FETCH_SPECIFIC_CTA_FOR_APPROVAL_SUCCESS': {
      return {
        ...state,
        fetchOnGoing: false,
        specificCtaForApproval: action.payload,
        uiState: {
          ...state.uiState,
          specificCtaForApproval: action.payload,
        },
      };
    }
    case 'CLEAR_SPECIFIC_CTA_FOR_APPROVAL': {
      return {
        ...state,
        specificCtaForApproval: initialCtaState.specificCtaForApproval,
        uiState: {
          ...state.uiState,
          specificCtaForApproval: initialCtaState.specificCtaForApproval,
        },
      };
    }

    case 'FETCH_CTA_FOR_APPROVAL_LIST':
    case 'FETCH_CTA_FOR_APPROVAL_LIST_WITH_FIRST_CTA': {
      return {
        ...state,
        fetchOnGoing: true,
        forApproval: state.forApproval,
        specificCtaForApproval: initialCtaState.specificCtaForApproval,
      };
    }
    case 'FETCH_CTA_FOR_APPROVAL_LIST_FAIL':
    case 'FETCH_CTA_FOR_APPROVAL_LIST_WITH_FIRST_CTA_FAIL': {
      return {
        ...state,
        fetchOnGoing: false,
        forApproval: initialCtaState.forApproval,
        specificCtaForApproval: initialCtaState.specificCtaForApproval,
      };
    }
    case 'FETCH_CTA_FOR_APPROVAL_LIST_SUCCESS': {
      return {
        ...state,
        fetchOnGoing: false,
        forApproval: action.payload,
      };
    }
    case 'FETCH_CTA_FOR_APPROVAL_LIST_WITH_FIRST_CTA_SUCCESS': {
      return {
        ...state,
        fetchOnGoing: false,
        forApproval: action.payload,
        uiState: {
          ...state.uiState,
        },
      };
    }
    case 'FETCH_CTA_ALREADY_PROCESSED_LIST': {
      return {
        ...state,
        fetchOnGoing: true,
        alreadyProcessed: state.alreadyProcessed,
      };
    }
    case 'FETCH_CTA_ALREADY_PROCESSED_LIST_FAIL': {
      return {
        ...state,
        fetchOnGoing: false,
        alreadyProcessed: initialCtaState.alreadyProcessed,
      };
    }
    case 'FETCH_CTA_ALREADY_PROCESSED_LIST_SUCCESS': {
      return {
        ...state,
        fetchOnGoing: false,
        alreadyProcessed: action.payload,
      };
    }

    case 'REJECT_CTA':
    case 'APPROVE_CTA': {
      return { ...state, approvalOnGoing: true };
    }
    case 'REJECT_CTA_FAIL':
    case 'APPROVE_CTA_FAIL': {
      return {
        ...state,
        approvalOnGoing: false,
        uiState: {
          ...state.uiState,
          rejectModeEnabled: false,
        },
      };
    }
    case 'REJECT_CTA_SUCCESS':
    case 'APPROVE_CTA_SUCCESS': {
      return {
        ...state,
        specificCtaForApproval: initialCtaState.specificCtaForApproval,
        approvalOnGoing: false,
        uiState: {
          ...state.uiState,
          specificCtaForApproval: initialCtaState.specificCtaForApproval,
          rejectModeEnabled: false,
        },
      };
    }
    case 'CHANGE_REJECT_MODE_ENABLED': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          rejectModeEnabled: action.isEnabled,
        },
      };
    }
    case 'FOCUS_CTA_FOR_APPROVAL_AT_INDEX': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          selectedCtaForApprovalIndex: action.ctaIndex,
        },
      };
    }
    case 'FETCH_COUNT_OF_WORKING_DAYS': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          workingDaysMessage: null,
        },
      };
    }
    case 'FETCH_COUNT_OF_WORKING_DAYS_SUCCESS': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          workingDaysMessage: {
            level: action.payload.level,
            title: action.payload.title,
            message: action.payload.message,
          },
        },
      };
    }
    case 'FETCH_COUNT_OF_WORKING_DAYS_FAIL': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          workingDaysMessage: null,
        },
      };
    }
    case 'FETCH_LATE_DECLARATION_WARNING': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
        },
      };
    }
    case 'FETCH_LATE_DECLARATION_WARNING_SUCCESS': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          lateDeclarationWarning: action.payload,
        },
      };
    }
    case 'FETCH_LATE_DECLARATION_WARNING_FAIL': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          lateDeclarationWarning: null,
        },
      };
    }
    case 'CLEAR_LATE_DECLARATION_WARNING': {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          lateDeclarationWarning: null,
        },
      };
    }
    case 'DUPLICATE_CTA': {
      return {
        ...state,
        forDuplication: {
          ...state.current,
          ...state.uiState.current,
          answers: state.uiState.current.answers.map((answer) => ({
            ...answer,
            validity: true,
          })),
        },
      };
    }
    case 'DUPLICATE_CTA_SUCCESS': {
      return {
        ...state,
        current: {
          ...state.current,
          startDate: null,
          endDate: null,
        },
        uiState: {
          ...state.uiState,
          current: {
            ...state.uiState.current,
            startDate: null,
            endDate: null,
          },
        },
      };
    }
    case 'CLEAR_CTA_FOR_DUPLICATION': {
      return {
        ...state,
        forDuplication: initialCtaState.forDuplication,
      };
    }
    case 'EXPORT_CTA_DASHBOARD':
      return { ...state, exportCtaDashboardOnGoing: true };
    case 'EXPORT_CTA_DASHBOARD_SUCCESS':
      return { ...state, exportCtaDashboardOnGoing: false };
    case 'EXPORT_CTA_DASHBOARD_FAIL':
      return { ...state, exportCtaDashboardOnGoing: false };
    case 'FETCH_QUESTIONS_SUCCESS':
      return onFetchQuestionsSuccess(state, action);
    case 'FETCH_PREVIEW_FROM_QUESTIONS_SUCCESS':
      return onFetchPreviewFromQuestionsSuccess(state, action);
    case 'FETCH_QUESTIONS_FOR_DUPLICATION_SUCCESS':
      return onFetchQuestionsForDuplicationSuccess(state, action);
    default: {
      return state;
    }
  }
}

function onResetCta(state: CtaState): CtaState {
  return {
    ...state,
    uiState: initialCtaState.uiState,
  };
}

function onFetchCtaForDuplicationSuccess(
  state: CtaState,
  payload: ComplianceTravelApproval
): CtaState {
  const answersFromCta = state.uiState.current.answers;
  return {
    ...state,
    fetchOnGoing: false,
    uiState: {
      ...state.uiState,
      current: {
        ...payload,
        answers: getAnswerForDuplicationSuccess(answersFromCta, payload),
        uuid: null,
        startDate: null,
        endDate: null,
        cta_id: null,
      },
    },
  };
}

function onUpdateCtaAnswer(
  state: CtaState,
  { uuid, value }: UpdateCtaAnswer
): CtaState {
  return patchUiStateCurrentCtaAnswer(state, uuid, 'answer', value);
}

function onUpdateCtaAnswerOnCcCheckbox(
  state: CtaState,
  { uuid, value }: UpdateCtaAnswerOnCcCheckbox
): CtaState {
  return patchUiStateCurrentCtaAnswer(state, uuid, 'ccEmail', value);
}

function onUpdateCtaAnswerOnMultiSelectAdd(
  state: CtaState,
  { uuid, value }: UpdateCtaAnswerOnMultiSelectAdd
): CtaState {
  const answer = state.uiState.current.answers.find(
    (ans) => ans.questionUuid === uuid
  );
  if (!answer) {
    return state;
  }
  const options = answer.answer ? answer.answer.split('\r\n') || [] : [];
  options.push(value);
  return patchUiStateCurrentCtaAnswer(
    state,
    uuid,
    'answer',
    value ? options.join('\r\n') : null
  );
}

function onUpdateCtaAnswerOnMultiSelectRemove(
  state: CtaState,
  { uuid, value }: UpdateCtaAnswerOnMultiSelectRemove
): CtaState {
  const answer = state.uiState.current.answers.find(
    (ans) => ans.questionUuid === uuid
  );
  if (!answer) {
    return state;
  }
  const options = answer.answer ? answer.answer.split('\r\n') || [] : [];
  const serlizedAnswer = options
    .filter((option) => option !== value)
    .join('\r\n');
  return patchUiStateCurrentCtaAnswer(
    state,
    uuid,
    'answer',
    value && serlizedAnswer ? serlizedAnswer : null
  );
}

function onUpdateCtaAnswerValidity(
  state: CtaState,
  { uuid, isValidated }: UpdateCtaAnswerValidity
): CtaState {
  return patchUiStateCurrentCtaAnswer(state, uuid, 'validity', isValidated);
}

function onFetchQuestionsSuccess(
  state: CtaState,
  { questions }: FetchQuestionsSuccess
): CtaState {
  return patchUiStateCurrentCtaAnswers(state, questions);
}

function onFetchPreviewFromQuestionsSuccess(
  state: CtaState,
  { questions }: FetchPreviewFromQuestionsSuccess
): CtaState {
  return patchUiStateCurrentCtaAnswers(state, questions);
}

function onFetchQuestionsForDuplicationSuccess(
  state: CtaState,
  { questions }: FetchQuestionsForDuplicationSuccess
): CtaState {
  return patchUiStateCurrentCtaAnswers(state, questions);
}
